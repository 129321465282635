import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { fetchProfileFromBungie } from "@/game-destiny2/fetches/fetch-profile.mjs";
import { upsertBungieProfile } from "@/game-destiny2/fetches/post-upsert-user-profile.mjs";
import { addProfile } from "@/game-destiny2/utils/actions.mjs";
import { oauthTokenHandler } from "@/game-destiny2/utils/auth-token-handler.mjs";
import { CLIENT_TYPE } from "@/game-destiny2/utils/redirect-to-bungie.mjs";
import type { BungieToken } from "@/game-destiny2/utils/token-model.mjs";
import { appLog } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

export default async function fetchData(_, searchParams) {
  const isFromApp = searchParams.get("client_type") === CLIENT_TYPE.app;
  if (isFromApp && !IS_APP) {
    globals.open(
      `blitz://open-url?link=${encodeURIComponent(
        `/bungie-oauth-redirect?${searchParams.toString()}`,
      )}`,
    );
    return;
  }

  const token = {} as BungieToken;
  searchParams.forEach((value, key) => {
    token[key] = value?.replaceAll(" ", "+");
  });

  const bungieId = token.bungie_uid;
  const validToken = !!(token.token && bungieId);
  appLog("[DESTINY2] Got Bungie OAuth Token", { bungieId, validToken });
  if (!validToken) return;

  await oauthTokenHandler.setToken(bungieId, token);
  let userProfile = readState.settings.loggedInAccounts.destiny2[bungieId];
  if (!userProfile?.memberships.length)
    userProfile = await fetchProfileFromBungie(bungieId);

  if (!userProfile || userProfile instanceof Error) {
    appLog("[DESTINY2] Fetch user profile error");
    await oauthTokenHandler.unsetToken(bungieId);
    return;
  }

  addProfile(userProfile);
  return upsertBungieProfile(userProfile);
}
